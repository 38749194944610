import React, { createContext, useContext } from "react";

interface ConfigProviderProps {
  children: React.ReactNode;
}

const defaultConfig = {
  auth: {
    clientId: "",
  },
  mapLayers: {
    bingMapsKey: "",
    mapboxAccessToken: "",
  },
  cesiumIonKey: "",
};

const ConfigContext = createContext(defaultConfig);

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  return (
    <ConfigContext.Provider
      value={{
        auth: {
          clientId: import.meta.env.IMJS_AUTH_CLIENT_CLIENT_ID ?? "",
        },
        mapLayers: {
          bingMapsKey: import.meta.env.IMJS_BING_MAPS_KEY ?? "",
          mapboxAccessToken: import.meta.env.IMJS_MAPBOX_ACCESS_TOKEN ?? "",
        },
        cesiumIonKey: import.meta.env.IMJS_CESIUM_ION_KEY ?? "",
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined || context === defaultConfig) {
    throw new Error("useConfig must be used within a ConfigContext");
  }
  return context;
};
