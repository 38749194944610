import {
  SvgClipboard,
  SvgImodelHollow,
  SvgMoon,
  SvgSun,
  SvgUsers,
  SvgWindowCollapse,
  SvgWindowFullScreen,
} from "@itwin/itwinui-icons-react";
import type { ThemeType } from "@itwin/itwinui-react";
import {
  Header as IuiHeader,
  HeaderBreadcrumbs,
  HeaderLogo,
  IconButton,
  MenuItem,
  useToaster,
} from "@itwin/itwinui-react";
import React, { useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

import { spreadIf } from "../../utils";
import { useAuth } from "../Auth/AuthProvider";
import { useCommonPathPattern } from "../MainLayout/useCommonPathPattern";
import { HeaderUserIcon } from "./HeaderUserIcon";
import { IModelHeaderButton } from "./IModelHeaderButton";
import { ProjectHeaderButton } from "./ProjectHeaderButton";
import { VersionHeaderButton } from "./VersionHeaderButton";

interface HeaderProps {
  theme: ThemeType;
  onThemeChange: () => void;
}

export const Header = ({ theme, onThemeChange }: HeaderProps) => {
  const { accessToken, signOut } = useAuth();
  const { section, iTwinId, iModelId } = useCommonPathPattern();
  const versionMatch = useMatch("/:section/itwin/:iTwinId/imodel/:iModelId/version/:namedVersionId");

  const [searchParams] = useSearchParams();
  const turnOffUi = searchParams.get("ui") === "none";

  const toaster = useToaster();
  // toaster.setSettings({ placement: "bottom" });

  const slimMatch = [
    !!useMatch("/view/design-review/itwin/:iTwinId/imodel/:iModelId"),
    !!useMatch("/view/itwin/:iTwinId/imodel/:iModelId"),
    !!useMatch("/view/itwin/:iTwinId/imodel/:iModelId/changeset/:changesetId"),
    !!useMatch("/view/itwin/:iTwinId/imodel/:iModelId/version/:namedVersionId"),
    !!useMatch("/view/itwin/:iTwinId/imodel/:iModelId/savedview/:savedviewId"),
    !!useMatch("/view/itwin/:iTwinId/imodel/:iModelId/mesh-export/:meshExportId"),
    !!useMatch("/view/cesium/itwin/:iTwinId/imodel/:iModelId/mesh-export/:meshExportId"),
  ].includes(true);

  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);

  return (
    !turnOffUi && (
      <IuiHeader
        appLogo={
          <HeaderLogo logo={<SvgImodelHollow />} onClick={() => navigate("/")}>
            {!slimMatch && "iTwin Viewer"}
          </HeaderLogo>
        }
        isSlim={!!slimMatch}
        breadcrumbs={
          <HeaderBreadcrumbs
            items={[
              ...spreadIf(
                iTwinId && (
                  <ProjectHeaderButton key="project" iTwinId={iTwinId} section={section} isActive={!iModelId} />
                )
              ),
              ...spreadIf(
                iModelId && <IModelHeaderButton key="iModel" iModelId={iModelId} iTwinId={iTwinId} section={section} />
              ),
              ...spreadIf(
                iModelId && section === "view" && (
                  <VersionHeaderButton
                    key="version"
                    iModelId={iModelId}
                    iTwinId={iTwinId}
                    versionId={versionMatch?.params.namedVersionId}
                    section={section}
                  />
                )
              ),
            ]}
          />
        }
        actions={[
          <IconButton key="theme" styleType="borderless" onClick={onThemeChange} label={"Toggle Theme"}>
            {theme === "light" ? <SvgMoon /> : <SvgSun />}
          </IconButton>,
          <IconButton
            key="Screen"
            styleType="borderless"
            onClick={async () => {
              if (!fullScreen) {
                await document.documentElement.requestFullscreen();
                setFullScreen(true);
              } else {
                await document.exitFullscreen();
                setFullScreen(false);
              }
            }}
            label={"Toggle Full Screen"}
          >
            {fullScreen ? <SvgWindowCollapse /> : <SvgWindowFullScreen />}
          </IconButton>,
          <HeaderUserIcon key={"user"} handleLogout={signOut} />,
        ]}
        menuItems={(close) => [
          // <Button
          //   as="a"
          //   key="connect-portal"
          //   href={window.location.href}
          //   target="_blank"
          //   styleType="borderless"
          //   startIcon={<SvgRedo />}
          //   onClick={close}
          // >
          //   Open in Connect
          // </Button>,
          <MenuItem
            key="access-token"
            startIcon={<SvgClipboard />}
            onClick={async () => {
              try {
                await window.navigator.clipboard.writeText(accessToken ?? "");
                toaster.positive("Copied access token to clipboard", {
                  duration: 3000,
                });
              } catch (error) {
                console.error("Failed to copy access token to clipboard", error);
              } finally {
                close();
              }
            }}
          >
            Copy Access Token
          </MenuItem>,
          ...spreadIf(
            iTwinId && (
              <MenuItem
                key="manage-members"
                startIcon={<SvgUsers />}
                onClick={() => {
                  navigate(`/members/itwin/${iTwinId}`);
                  close();
                }}
              >
                Manage Members
              </MenuItem>
            )
          ),
          // <MenuItem
          //   key="mango"
          //   startIcon={<SvgComputer />}
          //   sublabel="Technical Preview"
          //   onClick={() => {
          //     navigate("/mango");
          //     close();
          //   }}
          // >
          //   Mango
          // </MenuItem>,
        ]}
      />
    )
  );
};
