import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React, { useEffect } from "react";

import { AuthProvider } from "./components/Auth/AuthProvider";
import { MainApp } from "./components/MainApp";
import { ConfigProvider } from "./config/ConfigProvider";

const App: React.FC = () => {
  return (
    <>
      <ConfigProvider>
        <AuthProvider>
          <MainApp />
        </AuthProvider>
      </ConfigProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
