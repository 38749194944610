import {
  SvgArchive,
  SvgCalendar,
  SvgClock,
  SvgItwin,
  SvgList,
  SvgStar,
  SvgStarHollow,
  SvgUsers,
} from "@itwin/itwinui-icons-react";
import { Svg404 } from "@itwin/itwinui-illustrations-react";
import { FluidGrid, PageLayout } from "@itwin/itwinui-layouts-react";
import {
  Badge,
  Button,
  Flex,
  IconButton,
  MenuItem,
  NonIdealState,
  SearchBox,
  Tab,
  Tabs,
  Text,
  Tile,
} from "@itwin/itwinui-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../components/Auth/AuthProvider";
import { DefaultLoading } from "../../components/Loading";
import { useCommonPathPattern } from "../../components/MainLayout/useCommonPathPattern";
import { useDebounce } from "../../hooks";
import type { ITwinFull } from "../../types";

const PROJECT_TYPE_MAP = ["", "recents", "myprojects"];

const ITwinTile = ({
  iTwin,
  accessToken,
  path,
  favorites,
  refetchFavorites,
  isExternal,
}: {
  iTwin: ITwinFull;
  accessToken: string;
  path: string;
  favorites: ITwinFull[];
  refetchFavorites: () => Promise<any>;
  isExternal: boolean;
}) => {
  const navigate = useNavigate();
  const { status, data: iTwinThumbnail } = useQuery<{
    image: {
      id: string;
      smallImageName: string;
      smallImageUrl: string;
      largeImageName: string;
      largeImageUrl: string;
    };
  }>({
    queryKey: [`/itwins/${iTwin.id}/image`],
    retry: false,
  });
  const favoriteMutation = useMutation({
    mutationFn: async (id: string) => {
      await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/itwins/favorites/${id}`, {
        method: "POST",
        headers: {
          Authorization: accessToken,
          Accept: "application/vnd.bentley.itwin-platform.v1+json",
        },
      });
    },
  });
  const unfavoriteMutation = useMutation({
    mutationFn: async (id: string) => {
      await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/itwins/favorites/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: accessToken,
          Accept: "application/vnd.bentley.itwin-platform.v1+json",
        },
      });
    },
  });
  const updateStatusMutation = useMutation({
    mutationFn: async (iTwin: ITwinFull) => {
      await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/itwins/${iTwin.id}`, {
        method: "PATCH",
        headers: {
          Authorization: accessToken,
          Accept: "application/vnd.bentley.itwin-platform.v1+json",
        },
        body: JSON.stringify({
          status: iTwin.status?.toLowerCase() === "active" ? "inactive" : "active",
        }),
      });
    },
  });

  return (
    <Tile.Wrapper
      key={iTwin.id}
      isLoading={status === "pending"}
      status={
        iTwin.status?.toLowerCase() === "inactive"
          ? "negative"
          : iTwin.status?.toLowerCase() === "trial"
          ? "warning"
          : undefined
      }
    >
      <Tile.ThumbnailArea>
        {status === "success" && iTwinThumbnail ? (
          <Tile.ThumbnailPicture url={iTwinThumbnail.image.largeImageUrl} />
        ) : (
          <Tile.ThumbnailPicture>
            <SvgItwin />
          </Tile.ThumbnailPicture>
        )}
        <Tile.TypeIndicator>{isExternal && <Badge backgroundColor="warning">External</Badge>}</Tile.TypeIndicator>
        <Tile.QuickAction>
          <IconButton styleType="borderless">
            {favorites?.map((fav) => fav.id).includes(iTwin.id) ? (
              <SvgStar
                onClick={async () => {
                  await unfavoriteMutation.mutateAsync(iTwin.id);
                  await refetchFavorites();
                }}
              />
            ) : (
              <SvgStarHollow
                onClick={async () => {
                  await favoriteMutation.mutateAsync(iTwin.id);
                  await refetchFavorites();
                }}
              />
            )}
          </IconButton>
        </Tile.QuickAction>
        {iTwin.type && (
          <Tile.BadgeContainer>
            <Badge>{iTwin.type}</Badge>
          </Tile.BadgeContainer>
        )}
      </Tile.ThumbnailArea>
      <Tile.Name>
        <Tile.NameIcon />
        <Tile.NameLabel>
          <Tile.Action href={path}>{iTwin?.displayName}</Tile.Action>
        </Tile.NameLabel>
      </Tile.Name>
      <Tile.ContentArea>
        <Tile.Description>{iTwin?.number}</Tile.Description>
        {iTwin.createdDateTime && (
          <Tile.Metadata>
            <SvgClock /> {`Created on: ${new Date(iTwin.createdDateTime).toDateString()}`}
          </Tile.Metadata>
        )}
        <Tile.MoreOptions>
          {[
            <MenuItem
              key="manage-members"
              startIcon={<SvgUsers />}
              onClick={() => {
                window.open(
                  `https://${globalThis.IMJS_URL_PREFIX}connect-rbacportal.bentley.com/Manage/${iTwin.id}/users/`,
                  "_blank"
                );
                navigate(`/members/itwin/${iTwin.id}`);
              }}
            >
              Manage Members
            </MenuItem>,
            <MenuItem
              key="mutate-status"
              startIcon={<SvgArchive />}
              onClick={async () => {
                await updateStatusMutation.mutateAsync(iTwin);
              }}
              disabled={isExternal}
            >
              {iTwin.status?.toLowerCase() === "active" ? "Archive" : "Re-Activate"}
            </MenuItem>,
          ]}
        </Tile.MoreOptions>
      </Tile.ContentArea>
    </Tile.Wrapper>
  );
};

export const SelectProject = () => {
  const { accessToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const debouncedSearchValue = useDebounce(searchParams.get("search") ?? "", 1000);
  const { section } = useCommonPathPattern();

  const [projectType, setProjectType] = useState(PROJECT_TYPE_MAP.indexOf(searchParams.get("type") ?? ""));

  const requestType = projectType === 0 ? "favorites" : projectType === 1 ? "recents" : "";
  const endpoint = ["favorites", "recents"].includes(requestType) ? requestType : "";
  const subClass = `?subClass=Project`;
  const search = searchParams.get("type") && searchParams.get("search") ? `&$search=${searchParams.get("search")}` : "";

  const { data: primaryAccount } = useQuery<{ iTwin?: ITwinFull }>({
    queryKey: ["/itwins/myprimaryaccount"],
  });

  const {
    data: { iTwins: favoriteITwins },
    refetch: refetchFavorites,
  } = useQuery<{ iTwins?: ITwinFull[] }>({
    queryKey: [`/itwins/favorites${subClass}`],
    initialData: { iTwins: [] },
  });

  const { status, error, data, refetch } = useQuery<{ iTwins?: ITwinFull[] }>({
    queryKey: [`/itwins/${endpoint}${subClass}&includeInactive=true${search}`],
    // initialData: { iTwins: [] },
  });

  useEffect(() => {
    void refetch(); // refetch anytime you change the search
  }, [debouncedSearchValue, refetch]);

  return (
    <PageLayout className="idp-scrolling-container">
      <Tabs
        labels={[
          <Tab key="favorite" label="Favorite iTwins" startIcon={<SvgStar />} />,
          <Tab key="recents" label="Recent iTwins" startIcon={<SvgCalendar />} />,
          <Tab key="all" label="My iTwins" startIcon={<SvgList />} />,
        ]}
        onTabSelected={(index) => {
          setSearchParams((prev) => {
            index ? prev.set("type", PROJECT_TYPE_MAP[index]) : prev.delete("type");
            return prev;
          });
          setProjectType(index);
        }}
        activeIndex={projectType}
        type="borderless"
        contentClassName="grid-holding-tab"
        tabsClassName="grid-holding-tabs"
        orientation="horizontal"
        style={{ height: "100%" }}
      >
        <PageLayout.Header className="idp-content-margins">
          <SearchBox isDisabled={!searchParams.get("type")}>
            <SearchBox.Input
              placeholder={"Will search in name or number"}
              value={searchParams.get("search") ?? ""}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSearchParams((prev) => {
                  prev.set("search", value);
                  return prev;
                });
              }}
              onKeyDown={async (event) => {
                if (event.key === "Enter") {
                  await refetch();
                }
                if (event.key === "Escape") {
                  setSearchParams((prev) => {
                    prev.delete("search");
                    return prev;
                  });
                }
              }}
            />
            <SearchBox.Button
              label={"Search"}
              onClick={async () => {
                await refetch();
              }}
            />
          </SearchBox>
        </PageLayout.Header>
        <PageLayout.Content style={{ height: "100%" }} className="idp-content-margins">
          {status === "pending" && <DefaultLoading />}
          {status === "error" && (
            <NonIdealState
              title="Error"
              description={error?.message}
              svg={<Svg404 />}
              actions={
                <Button styleType={"high-visibility"} onClick={() => refetch()}>
                  Retry
                </Button>
              }
            />
          )}
          {status === "success" &&
            data?.iTwins &&
            (data.iTwins.length > 0 ? (
              <FluidGrid>
                {data.iTwins.map((iTwin) => (
                  <ITwinTile
                    key={iTwin.id}
                    iTwin={iTwin}
                    accessToken={accessToken!}
                    path={`/${section}/itwin/${iTwin.id}`}
                    favorites={favoriteITwins ?? []}
                    refetchFavorites={refetchFavorites}
                    isExternal={iTwin.iTwinAccountId !== primaryAccount?.iTwin?.id}
                  />
                ))}
              </FluidGrid>
            ) : (
              <Flex>
                <Text variant="body">No iTwins found.</Text>
              </Flex>
            ))}
        </PageLayout.Content>
      </Tabs>
    </PageLayout>
  );
};
