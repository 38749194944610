import "@itwin/itwinui-layouts-css/styles.css";
import "@itwin/itwinui-react/styles.css";
import { WebStorageStateStore } from "oidc-client-ts";
import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";

import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
globalThis.IMJS_URL_PREFIX = import.meta.env.IMJS_URL_PREFIX || "";

const container = document.getElementById("root");
const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
root.render(
  // <React.StrictMode>
  <AuthProvider
    authority={`https://${globalThis.IMJS_URL_PREFIX}ims.bentley.com`}
    client_id={import.meta.env.IMJS_AUTH_CLIENT_CLIENT_ID ?? ""}
    redirect_uri={`${window.location.origin}/signin-callback`}
    scope={[
      "itwinjs", // needed for internal backends
      "general-purpose-imodeljs-backend",
      "navigator-backend",
      "itwin-platform",
    ].join(" ")}
    userStore={new WebStorageStateStore({ store: window.localStorage })}
    post_logout_redirect_uri={window.location.origin}
    onSigninCallback={() => {
      window.history.replaceState({}, document.title, window.location.origin);
      window.location.reload();
    }}
    // do popup signin when window.self !== window.top
    // skipSigninCallback={window.location.pathname === "/signin-callback"}
  >
    <App />
  </AuthProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
