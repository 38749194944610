import React, { lazy, Suspense } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import { DefaultLoading } from "../../components/Loading";
import { SelectionRouter } from "../SelectionRouter/SelectionRouter";

const LazyViewer = lazy(() => import("./Viewer"));

export const ViewRouter = () => {
  return (
    <Suspense fallback={<DefaultLoading />}>
      <Routes>
        <Route path="*" element={<SelectionRouter />} />
        {[
          "itwin/:iTwinId/imodel/:iModelId",
          "itwin/:iTwinId/imodel/:iModelId/changeset/:changesetId",
          "itwin/:iTwinId/imodel/:iModelId/version/:namedVersionId",
          "itwin/:iTwinId/imodel/:iModelId/savedview/:savedviewId",
          "itwin/:iTwinId/imodel/:iModelId/mesh-export/:meshExportId",
        ].map((path) => (
          <Route key={path} path={path} element={<LazyViewer />} />
        ))}

        <Route
          key={"design-review"}
          path={"design-review/itwin/:iTwinId/imodel/:iModelId"}
          element={<DesignReviewViewer />}
        />
      </Routes>
    </Suspense>
  );
};

const DesignReviewViewer = () => {
  const { iTwinId, iModelId } = useParams();
  return (
    <iframe
      title={"Design Review"}
      height={"100%"}
      width={"100%"}
      referrerPolicy={"no-referrer"}
      seamless={true}
      src={`https://${globalThis.IMJS_URL_PREFIX}connect-imodelweb.bentley.com/imodeljs/?projectId=${iTwinId}&iModelId=${iModelId}`}
    />
  );
};
